@mixin bp($breakpoint) {
  $num: $breakpoint;
  @if $breakpoint == sm {
    $num: 576;
  }
  @if $breakpoint == md {
    $num: 768;
  }
  @if $breakpoint == lg {
    $num: 992;
  }
  @if $breakpoint == xl {
    $num: 1200;
  }
  @if $breakpoint == xxl {
    $num: 1400;
  }

  @media only screen and (min-width: ($num + px)) {
    @content;
  }
}

@mixin regular {
  font-family: nobel, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin bold {
  font-family: nobel, sans-serif;
  font-weight: 700;
  font-style: normal;
}

body {
  @include regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  margin: 0;
}
