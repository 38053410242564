@mixin bp($breakpoint) {
  $num: $breakpoint;
  @if $breakpoint == sm {
    $num: 576;
  }
  @if $breakpoint == md {
    $num: 768;
  }
  @if $breakpoint == lg {
    $num: 992;
  }
  @if $breakpoint == xl {
    $num: 1200;
  }
  @if $breakpoint == xxl {
    $num: 1400;
  }

  @media only screen and (min-width: ($num + px)) {
    @content;
  }
}

.App {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  @include bp(1024) {
    max-width: 1024px;
    max-height: 1024px;
  }

  @include bp(1080) {
    max-width: 1080px;
    max-height: 1080px;
    flex-direction: row;
  }

  #site-map-outer {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 7rem;

    @include bp(1080) {
      margin-top: 1rem;
    }

    #site-map-inner {
      position: relative;
      width: 100vw;
      height: calc(100vw * 2579 / 2342);

      @include bp(1080) {
        width: calc(1000px * 2342 / 2579);
        height: 1000px;
      }

      #background {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        max-height: 100%;
        width: auto;
        cursor: move;
      }

      #overlay {
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        max-height: 100%;
        width: auto;

        g {
          pointer-events: all;
        }

        polygon {
          fill: none;
          cursor: pointer;
        }

        circle {
          opacity: 1;
          fill: transparent;

          &:hover {
            cursor: pointer;
          }

          &.available {
            fill: #13df13;
          }

          &.reserved {
            fill: yellow;
          }

          &.sold {
            fill: red;
          }

          &.quick-move-in {
            fill: purple;
          }
        }
      }
    }
  }

  #legend {
    z-index: -1;
    position: absolute;
    top: 0%;
    left: 2%;

    @include bp(1080) {
      top: 2%;
      left: 8%;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        display: flex;
        align-items: center;
        font-size: 1.25rem;
        color: #585858;
        margin-bottom: 0.75rem;

        svg {
          width: 25px;
          height: 25px;
          margin-right: 0.5rem;
        }
      }
    }
  }

  #zoom {
    position: absolute;
    top: 1%;
    right: 1%;

    button {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      border: none;
      cursor: pointer;
      padding: 0;
    }
  }

  #hoods {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;

    @include bp(696) {
      position: absolute;
      left: -2%;
      bottom: 13%;
      flex-direction: row;
      margin: 0;
    }

    @include bp(900) {
      left: 4%;
      bottom: 20%;
    }

    @include bp(1080) {
      left: 2%;
      bottom: 22%;
    }

    .hood {
      font-size: 1.2rem;
      text-align: center;
      color: #585858;
      padding: 0 0.5rem;
      margin: 0 1rem;

      @include bp(1080) {
        font-size: 1.4rem;
      }

      .logo {
        height: 180px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-bottom: 1rem;

        img {
          width: 200px;
          height: auto;

          @include bp(1080) {
            width: 250px;
          }
        }
      }

      .type {
        text-transform: uppercase;
        margin-bottom: 1rem;

        @include bp(696) {
          max-width: 200px;
          height: 46px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        @include bp(900) {
          max-width: unset;
          height: unset;
        }
      }

      .sqft {
        margin-bottom: 1rem;
      }

      .price {
        margin-bottom: 1rem;
      }
    }
  }

  .btn {
    font-size: 0.85rem;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 2px;
    color: #ffffff;
    border-radius: 5px;
    padding: 0.6rem 0.75rem;

    @include bp(900) {
      font-size: 1rem;
    }
  }
}

#compass {
  z-index: -1;
  position: absolute;
  bottom: 2%;
  right: 35%;
  display: none;

  @include bp(1080) {
    display: block;
  }

  img {
    width: 100px;
    height: auto;
  }
}

.popup-content {
  width: 330px;
  background: rgb(255, 255, 255);
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  padding: 1rem 1rem 1.5rem;
  margin: auto;

  .close {
    position: absolute;
    top: 0.45rem;
    right: 0.35rem;
    width: 22px;
    height: 22px;
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
      stroke: #585858;
    }
  }

  .logo {
    width: 50%;
    height: auto;
    margin-bottom: 0.5rem;
  }

  .elevation {
    max-width: 100%;
    height: auto;
  }

  .details {
    text-align: center;

    .title {
      font-size: 1.7rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin: 0.75rem auto 0.5rem;
    }

    .specs {
      font-size: 1.25rem;
      line-height: 1.75;
      letter-spacing: 0.25px;
      color: #585858;
      margin: 0 auto 1.25rem;
    }

    a {
      font-size: 1.25rem;
      letter-spacing: 0.25px;
      text-transform: uppercase;
      text-decoration: none;

      svg {
        width: 24px;
        height: 18px;
        margin-left: 0.25rem;
      }
    }
  }
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.1);
}

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
  }
  1% {
    transform: scale(0.75) translateY(100px);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
}
.popup-content {
  animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}
